class Callbacks {
  static filterToFormHandler(extra_filter) {
    return function filterToForm(filter) {
      return {
        driver_id: extra_filter?.driver_id,
        do: {
          auth: {
            gsm: !!extra_filter?.client_phone ? `+${extra_filter?.client_phone}` : undefined
          }
        },
        operator: extra_filter?.operator_id,
        ...filter
      }
    }
  }
}

export default Callbacks
