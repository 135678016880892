import { useCallback } from "react"

const useBlobHandler = (path, params, options) => {
  return useCallback(async (fetchBlob) => {
    const response = await fetchBlob()

    if (options?.type === 'audio') {
      const blob = new Blob([response], { type: 'audio/mpeg' })
      return window.URL.createObjectURL(blob)
    }
    else if (options?.type === 'jpeg') {
      const blob = new Blob([response], { type: 'image/jpeg' })
      return window.URL.createObjectURL(blob)
    }
    else {
      const urlObject = window.URL.createObjectURL(response)
      window.open(urlObject)
    }
  }, [])
}

export default useBlobHandler