import _ from 'lodash'
import React from 'react'
import { hooks } from '@front/volcanion'
const withContainer = Component => props => {
  const { model_name, model_func, populate, sortBy, sort, default_limit, search_id, enableLogQuery, watch_type } = props
  const DEFAULT_PAGE_SIZE = hooks.useRelayConstant('DEFAULT_PAGE_SIZE')
  const [data, onSearch, searchStatus, searchFunctions] = hooks.useModelSearch(model_name, model_func || 'get', {
    search_id,
    populate: populate,
    sort,
    default_limit: default_limit || DEFAULT_PAGE_SIZE,
    sortBy: sortBy,
    enableLogQuery,
    enableCount: true,
    watch_type
  })

  const [router] = hooks.useRouter()

  const authorization = hooks.useRouterContext()
  const mergedProps = {
    data,
    onSearch: searchFunctions.search,
    searchStatus,
    searchFunctions,
    router,
    canCreate: authorization?.create
  }
  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
