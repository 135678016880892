import useModel from '../../store/useModel'
import { useRelayConstants, useRelayFeatures } from '../../relay'
import { useActiveUser } from '../user'

const getOrigin = (active_user) => {
  if (!!active_user?.driverinfo)
    return 'driver'
  else if (!!active_user?.customerinfo)
    return 'customer'
  else if (!!active_user?.staffinfo)
    return 'operator'
  return null
}

const useCanCalls = (order_id) => {
  const [order] = useModel('order', [order_id], { single: true, populate: ['service', 'do.commercial_formula'] })

  const {
    CLIENT_CAN_CALL_DRIVER,
    CLIENT_CAN_CALL_CENTRAL,
    DRIVER_CAN_CALL_CENTRAL,
    DRIVER_CAN_CALL_CLIENT
  } = useRelayFeatures(['CLIENT_CAN_CALL_DRIVER', 'CLIENT_CAN_CALL_CENTRAL', 'DRIVER_CAN_CALL_CENTRAL', 'DRIVER_CAN_CALL_CLIENT'])

  const {
    CENTRAL_PHONE
  } = useRelayConstants(['CENTRAL_PHONE'])

  const [active_user] = useActiveUser({ populate: ['driverinfo'] })

  const origin = getOrigin(active_user)
  const isB2B = !!order?.service
  const can_call_driver = isB2B ? order?.service?.can_call_driver : order?.do?.commercial_formula?.can_call_driver
  const can_call_booker = isB2B ? order?.service?.can_call_booker : order?.do?.commercial_formula?.can_call_booker
  const can_driver_call = !!active_user?.driverinfo?.can_call_booker

  switch (origin) {
    case 'customer':
      return [{
        canCallClient: false,
        canCallCentral: !!CLIENT_CAN_CALL_CENTRAL && !!CENTRAL_PHONE,
        canCallDriver: !!CLIENT_CAN_CALL_DRIVER && !!can_call_driver
      }]
    case 'operator':
      return [{
        canCallClient: true,
        canCallCentral: false,
        canCallDriver: true
      }]
    case 'driver':
      return [{
        canCallClient: !!DRIVER_CAN_CALL_CLIENT && (!!can_call_booker || !!can_driver_call),
        canCallCentral: !!DRIVER_CAN_CALL_CENTRAL && !!CENTRAL_PHONE,
        canCallDriver: false
      }]
    default:
      return [{
        canCallClient: false,
        canCallCentral: false,
        canCallDriver: false
      }]
  }
}

export default useCanCalls