import React, { useCallback } from 'react'
import { Button, Box, Fade, Icon, Typography } from '@mui/material'
import { hooks, I18n } from '@front/volcanion'
import TypeUtils from '@front/volcanion/utils/type'
import ViewSelector from '@front/volcanion/components/ViewSelector'
import { ExportButton, FormTable } from '..'
import withForm from './form'
import { useDisplayExport } from '../../hooks'

const SearchChild = React.memo(ViewSelector)
const SearchForm = React.memo(ViewSelector)
const SearchTable = React.memo(ViewSelector)
const SearchCreateButton = React.memo(ViewSelector)
const SearchActionButton = React.memo(ViewSelector)
const FormAction = React.memo(ViewSelector)
const FormContent = React.memo(ViewSelector)

const CreateButton = ({ hideIcon, router, label, ...rest }) =>
  <Button
    color='primary'
    variant='outlined'
    onClick={() => router.navigate(`${router.location.pathname}/create`)}
    startIcon={!hideIcon ? <Icon>{'add'}</Icon> : undefined}
    {...rest}
  >
    <Typography variant='subtitle'>{label}</Typography>
  </Button>

const FormDecorator = ({
  children,
  disableButton,
  _controlsButton,
  model_name,
  onReset = _.noop,
  getButtonStyleProps = () => ({}),
  getButtonSubmitLabel = () => I18n.t('action.validate'),
  getButtonResetLabel = () => I18n.t('action.reset')
}) => {
  const onSubmit = hooks.useFormFunction('requestSubmit')
  const reset = hooks.useFormFunction('reset')
  const [display_export] = useDisplayExport()
  const formToFilter = hooks.useLocalContext('formToFilter')

  const formContentChildren = TypeUtils.allByType(children, FormContent)
  const formContentChildrenView = <ViewSelector {...TypeUtils.getProps(formContentChildren)}>{formContentChildren}</ViewSelector>

  const formActionChildren = TypeUtils.allByType(children, FormAction)
  const formActionChildrenView = <ViewSelector {...TypeUtils.getProps(formActionChildren)} model_name={model_name}>{formActionChildren}</ViewSelector>

  const otherChildren = TypeUtils.withoutTypes(children, FormContent, FormAction)

  const onResetClick = useCallback(e => {
    reset(e)
    onReset()
  }, [reset, onReset])

  return (
    <>
      <Box m={1}>{formContentChildrenView}</Box>
      {otherChildren}
      {!disableButton &&
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'center', ...getButtonStyleProps() }}>
          <Button onClick={onSubmit} variant={'contained'} sx={{ mr: 1 }} {..._controlsButton} size='small'>{getButtonSubmitLabel()}</Button>
          <Button onClick={onResetClick} {..._controlsButton}>{getButtonResetLabel()}</Button>
          {display_export && <ExportButton model_name={model_name} getFilter={formToFilter} />}
          {formActionChildrenView}
        </Box>
      }
    </>
  )
}
const FormWrapper = React.memo(withForm(FormDecorator))

const SearchFrame = (props) => {
  const {
    data,
    onSearch,
    model_name,
    allowCreate = true,
    searchStatus,
    searchFunctions,
    router,
    children,
    createPlacement = 'top',
    createButtonProps,
    enableFade = true,
    formTableName = `${model_name}_list`,
    formSearchName = `${model_name}_search`,
    canCreate
  } = props

  const extraChildren = TypeUtils.allByType(children, SearchChild)
  const formChildren = TypeUtils.allByType(children, SearchForm)
  const tableChildren = TypeUtils.allByType(children, SearchTable)
  const formChildProps = TypeUtils.getProps(_.head(formChildren))
  const buttonChildren = TypeUtils.allByType(children, SearchActionButton)
  const createButtonChildren = _.isEmpty(TypeUtils.allByType(children, SearchCreateButton)) ? <CreateButton router={router} {...createButtonProps} /> : TypeUtils.allByType(children, SearchCreateButton)
  const requestSubmit = hooks.useGlobalFormFunction(_.get(formChildProps, 'name') || `${model_name}_search`, 'requestSubmit')
  const buttonChildrenView = <ViewSelector router={router} {...TypeUtils.getProps(buttonChildren)}>{buttonChildren}</ViewSelector>
  return (<>
    <Fade in={enableFade} timeout={2000}>
      <Box sx={{ flex: 1 }}>
        {!!allowCreate && !!canCreate && createPlacement === 'top' && <Box sx={!_.isEmpty(buttonChildren) ? { display: "flex", justifyContent: "space-between" } : { flex: 1 }}>
          {buttonChildrenView}
          {createButtonChildren}
        </Box>}
        {_.map(formChildren, (formChild, index) => <FormWrapper key={index} onSearch={onSearch} name={formSearchName} model_name={model_name} {...TypeUtils.getProps(formChild)} />)}
        {_.map(extraChildren, (extraChild, key) => <ViewSelector  {...TypeUtils.getProps(extraChild)} key={key}>{extraChild}</ViewSelector>)}
        {_.map(tableChildren, (tableChild, index) => (
          <FormTable
            key={index}
            name={formTableName}
            model_name={model_name}
            loadOnMount={false}
            controller={[data, requestSubmit, searchStatus, searchFunctions]}
            tableProps={{
              onRowClick: (id) => router.navigate(`${id}`),
              ..._.get(TypeUtils.getProps(tableChild, true), 'tableProps')
            }}
            {..._.omit(TypeUtils.getProps(tableChild, true), 'tableProps')}
          >
            {TypeUtils.getChildren(tableChild)}
          </FormTable>
        ))}
        {!!allowCreate && !!canCreate && createPlacement === 'bottom' && <Box sx={!_.isEmpty(buttonChildren) ? { display: "flex", justifyContent: "space-between" } : { flex: 1 }}>
          {buttonChildrenView}
          {createButtonChildren}
        </Box>}
      </Box>
    </Fade>
  </>
  )
}

export default React.memo(SearchFrame)

export {
  SearchForm,
  SearchTable,
  SearchActionButton,
  SearchCreateButton,
  SearchChild,
  FormAction,
  FormContent
}
