import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { hooks } from '@front/volcanion'
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
  useOutletContext,
} from "react-router-dom"
function paramsToObject(entries) {
  const result = {}
  for (const key of entries.keys()) {
    result[key] = entries.getAll(key);
  }
  return result;
}
const useRouter = () => {
  const [router_state, setState, clearState] = hooks.useGlobalState('router')
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const context = useOutletContext()
  const [search_params, setSearchParams] = useSearchParams()
  const ImprovedNavigate = useCallback((path, { state, ...options } = {}) => {
    setState(state)
    return _.isNumber(path) ? navigate(path, options) : navigate(_.trimEnd(_.replace(path, '//', '/'), '/') || '/', options)
  }, [navigate, setState])
  const ImprovedRouter = useMemo(() => ({ navigate: ImprovedNavigate, location, params, search_params: paramsToObject(search_params), setSearchParams, state: router_state, context, setState, clearState }),
    [_.get(location, 'key'), ImprovedNavigate, router_state, setState, clearState, context]
  )
  return [ImprovedRouter]
}

export default useRouter