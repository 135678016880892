import React, { useEffect, useCallback } from 'react'
import { hooks } from '@front/volcanion'


import OnKeyDown from './onKeyDown'

const withContainer = Component => ({ record_id, handleBillingAction }) => {
  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['events'] })

  const approve = useCallback(() => handleBillingAction('approve', record_id), [handleBillingAction, record_id])
  const refuse = useCallback(() => handleBillingAction('refuse', record_id), [handleBillingAction, record_id])
  const billing_status_color = hooks.useBillingStatusColor(order)
  const billing_status = order?.billing_status

  const ctrlKeyMap = useCallback(OnKeyDown.ctrlKeyMapHandler(refuse), [refuse, billing_status])
  const baseKeyMap = useCallback(OnKeyDown.baseKeyMapHandler(approve), [approve, billing_status])
  const onKeyDown = useCallback(OnKeyDown.onKeyDownHandler(ctrlKeyMap, baseKeyMap), [ctrlKeyMap, baseKeyMap])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const mergedProps = {
    refuse,
    approve,
    events: order?.events,
    order_client_id: order?.order_client_id,
    billing_status,
    billing_status_color
  }

  return (

    <Component {...mergedProps} />

  )
}

export default withContainer
