import { I18n } from '@front/volcanion'

class Callbacks {
  static handleExportHandler(openDialog, model_name) {
    return function handleExport() {
      return openDialog(`export_${model_name}`, {
        title: I18n.t('sqr.dialog.export.title'),
        description: I18n.t('sqr.dialog.export.description')
      })
    }
  }
  static onSubmitHandler(form_search_values, active_user, navigate, exportModel, getFilter) {
    return async function onSubmit({ template }, extra, meta, state) {
      const filter = !!getFilter
        ? getFilter(form_search_values, extra, meta, state)
        : form_search_values

      await exportModel({ filter, options: { template } })
      navigate('/export', { state: { name: 'export', operator: active_user } })
    }
  }
}

export default Callbacks
