import { useCallback } from 'react'

import { ProviderInstance } from '../../../provider'

const useGlobalStateChange = (key) => {
  return useCallback(value =>
    ProviderInstance.getProvider('global').setValues([{ path: key, value }]
    ), [key])
}

export default useGlobalStateChange
