import React from 'react'

import { I18n } from '@front/volcanion'
import OrderForm from '@abra/elements/OrderForm'

import { Box, Typography, Collapse } from '@mui/material'

const SearchForm = ({ toggle, setToggle }) => {
  return (
    <Box sx={{
      boxShadow: 3,
      color: 'primary.main',
      '&:hover': !!toggle ? undefined : {
        backgroundColor: 'primary.main',
        color: 'extraWhite'
      }
    }}
    >
      <Box onClick={() => setToggle(!toggle)}>
        <Box sx={{ m: 1, p: 1 }}>
          <Typography variant={'h5'} color='inherit'> {I18n.t('order.search')} </Typography>
        </Box>
      </Box>
      <Collapse in={!!toggle}>
        <Box p={1}>
          <OrderForm mode='order' />
        </Box>
      </Collapse>
    </Box >
  )
}

export default React.memo(SearchForm)
