import { usePathname } from "../../../provider/hooks"
import { useModelSearch } from "../../store"

const useCurrentPage = () => {
  const [authorized_pages, , status] = useModelSearch('page', 'getAuthorized', { default_limit: -1, initial_filter: {}, populate: ['roles', 'authorizations'] })
  const current_path = usePathname()
  const current_page = _.find(authorized_pages, (page) => !_.isEmpty(_.trimEnd(current_path, '/').match(new RegExp(`^${_.trimEnd(page.matcher, '/')}$`))))
  return [current_page, status]
}

export default useCurrentPage
