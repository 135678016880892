// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #f1f1f1;
  height: 100%;
}

#root {
  margin: 0px;
  padding: 0px;
}

.root {
  padding: 0;
  margin: 0;
  height: calc(100vh - 50px);
  margin-top: 50px;
  width: calc(100% - 50px);
  margin-left: 50px;
  transition: width 0.1s ease-in, margin 0.1s ease-in;
}
.root.shift {
  width: calc(100% - 240px);
  margin-left: 240px;
  transition: width 0.1s ease-in, margin 0.1s ease-in;
}

.safearea {
  padding-top: 40px;
  margin-left: 50px;
}

.full-screen-map {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles.scss","webpack://./variables.scss"],"names":[],"mappings":"AAGA;EACE,yBAAA;EACA,YAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;AAFF;;AAKA;EACE,UAAA;EACA,SAAA;EACA,0BAAA;EACA,gBCjBc;EDkBd,wBAAA;EACA,iBCjBoB;EDkBpB,mDAAA;AAFF;AAIE;EACE,yBAAA;EACA,kBCrBiB;EDsBjB,mDAAA;AAFJ;;AAMA;EACE,iBAAA;EACA,iBC7BoB;AD0BtB;;AAMA;EACE,UAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;AAHF;;AAMA;EACE,uBAAA;AAHF","sourcesContent":["@use \"../../variables.scss\";\n\n\nbody {\n  background-color: #f1f1f1;\n  height: 100%;\n}\n\n#root {\n  margin: 0px;\n  padding: 0px;\n}\n\n.root {\n  padding: 0;\n  margin: 0;\n  height: calc(100vh - variables.$topbar-height);\n  margin-top: variables.$topbar-height;\n  width: calc(100% - variables.$sidebar-width-close);\n  margin-left: variables.$sidebar-width-close;\n  transition: width variables.$transition-time ease-in, margin variables.$transition-time ease-in;\n\n  &.shift {\n    width: calc(100% - variables.$sidebar-width-open);\n    margin-left: variables.$sidebar-width-open;\n    transition: width variables.$transition-time ease-in, margin variables.$transition-time ease-in;\n  }\n}\n\n.safearea {\n  padding-top: 40px;\n  margin-left: variables.$sidebar-width-close;\n}\n\n.full-screen-map {\n  padding: 0;\n  margin: 0;\n  height: 100%;\n  width: 100%;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}","$topbar-height: 50px;\n$safearea-padding: 10px;\n$sidebar-width-close: 50px;\n$sidebar-width-open: 240px;\n$transition-time: 0.1s;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
