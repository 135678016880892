import { useCallback } from 'react'
import { useRelayConstants } from '../../relay'
import { useModelFunction } from '../../store'
import { I18n } from '../../..'
import useCanCalls from './useCanCalls'

const getDisabled = (target, canCallCentral, canCallDriver, canCallClient) => {
    switch (target) {
        case 'customer':
            return !canCallClient
        case 'central':
            return !canCallCentral
        case 'driver':
            return !canCallDriver
        default:
            return true
    }
}

const getLabel = (target, CLIENT_COMMERCIAL_NAME) => {
    switch (target) {
        case 'customer':
            return I18n.t('voc.call.customer')
        case 'driver':
            return I18n.t('voc.call.driver')
        case 'central':
            return I18n.t('voc.call.central', { name: CLIENT_COMMERCIAL_NAME })
        default:
            return ''
    }
}

const useCall = (order_id, target) => {
    const { CLIENT_COMMERCIAL_NAME } = useRelayConstants(['CLIENT_COMMERCIAL_NAME'])
    const [call] = useModelFunction('vonageconversation', 'call')
    const [{ canCallClient, canCallCentral, canCallDriver }] = useCanCalls(order_id)

    const disabled = getDisabled(target, canCallCentral, canCallDriver, canCallClient)
    const label = getLabel(target, CLIENT_COMMERCIAL_NAME)

    const onCall = useCallback(async (called_phone) => await call({ called_phone, target, order_id }), [call, target, order_id])

    return [{ label, disabled, canCallDriver, canCallCentral, canCallClient }, { onCall }]
}

export default useCall