import useColor from '../useColor'

const useThemeMode = props => {
  const [colorScheme, setColorMode] = useColor()
  const isDarkMode = colorScheme === 'dark'

  return [{ colorScheme, isDarkMode }, setColorMode]
}

export default useThemeMode
