import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { LoadingButton } from '@mui/lab'
import Loader from '../../Loader'

const SubmitButton = ({ label, form_name, extra, loading, ...rest }) => {
  const current_form = hooks.useFormName()
  const isSubmitting = hooks.useGlobalFormStatus(form_name || current_form, 'isSubmitting')
  const requestSubmit = hooks.useGlobalFormFunction(form_name || current_form, 'requestSubmit')
  const isLoading = !!isSubmitting || loading

  const onSubmit = useCallback(() => {
    return requestSubmit(extra)
  }, [requestSubmit, extra])

  return (
    <LoadingButton
      loading={isLoading}
      loadingIndicator={(
        <Loader
          size={30}
          isLoading={isLoading}
        />
      )}
      onClick={onSubmit}
      variant='contained'
      {...rest}
    >
      {label}
    </LoadingButton>
  )

}

export default React.memo(SubmitButton)
