import { useCallback } from 'react'

import _ from 'lodash'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import isEqual from 'react-fast-compare'

import { ProviderInstance } from '../../../provider'

const useGlobalStateValue = (key) => {
  return useSyncExternalStoreWithSelector(
    useCallback(ProviderInstance.getProvider('global').prepareAttachedSubscription(key), [key]),
    useCallback(() => ProviderInstance.getProvider('global').getListenerResult(key), [key]),
    null,
    _.identity,
    isEqual
  )

}

export default useGlobalStateValue
