export default {
  and: "et",
  error: {
    exist: "{{value}} déjà utilisé",
  },
  field: {
    required: "Requis",
    format: "Format incorrect"
  },
  form: {
    validation: {
      failed: "Enregistrement impossible. Vérifiez les données saisies."
    },
    submit: {
      success: "Enregistrement réussi",
      error: "Une erreur est survenue lors de l'enregistrement"
    }
  },
  day: {
    short: 'j'
  },
  date: {
    past: "Date dans le passée"
  },
  currency: {
    eur: "{{price}}€",
    usd: "{{price}}$",
    gbp: "{{price}}£"
  },
  call: {
    driver: 'Appeler le chauffeur',
    central: 'Appeler {{name}}',
    customer: 'Appeler le client'
  },
  calendar: {
    sameDay: "[Aujourd'hui] HH:mm",
    nextDay: "[Demain] HH:mm",
    nextWeek: "dddd HH:mm",
    lastDay: "[Hier] HH:mm",
    lastWeek: "dddd [dernier] HH:mm",
    sameElse: "DD/MM/YYYY HH:mm"
  },
  calendar_long: {
    sameDay: "[Aujourd'hui] HH:mm",
    nextDay: "[Demain] HH:mm",
    nextWeek: "[Le] dddd HH:mm",
    lastDay: "[Hier] HH:mm",
    lastWeek: "dddd [dernier] HH:mm",
    sameElse: "[le] DD/MM/YYYY HH:mm"
  },
  calendar_complete: {
    sameDay: "[Aujourd'hui] [à] HH[h]mm",
    nextDay: "[Demain] [à] HH[h]mm",
    nextWeek: "[Le] dddd [à] HH[h]mm",
    lastDay: "[Hier] [à] HH[h]mm",
    lastWeek: "dddd [dernier] [à] HH[h]mm",
    sameElse: "[le] DD/MM/YYYY [à] HH[h]mm"
  },
  calendar_day: {
    sameDay: "[aujourd'hui]",
    nextDay: "[demain]",
    nextWeek: "[le] dddd DD MMMM",
    lastDay: "[hier]",
    lastWeek: "dddd [dernier]",
    sameElse: "[le] DD/MM/YYYY"
  }
}
